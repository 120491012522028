import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {kitFetchExternal} from "@kulturit/header-state";

export const useResponses = ({rowsPerPage=null, sort="sort", start=null, topic_id=null, orderBy='new', descending=true}) => {
    const dispatch = useDispatch();
    const [responses, setResponses] = useState({responses: [], count: 0, rows: rowsPerPage, sort: orderBy, start: start});

    useEffect(() => {
        kitFetchExternal(`/minne-api/response/search?rows=${rowsPerPage}&sort=${orderBy}&start=${start}&topic_id=${topic_id}`)
            .then(json => setResponses(json))
            .catch(err => console.log("Responses fetch error: " + err));
    },  [dispatch, setResponses, rowsPerPage, orderBy, start, topic_id]);

    return responses;
};
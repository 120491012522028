import React, {useEffect} from 'react'
import {setFinderMode} from "../../state/layout/layout-action";
import {useDispatch} from "react-redux";

export const useFinderMode = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setFinderMode());
    }, [dispatch]);
};

import React from 'react';
import {useFinderMode} from "../layout/hooks/useFinderMode";
import {FinderSection} from "@frontend-components/admin";
import LayoutFinder from "@frontend-components/admin/es/components/Finder/LayoutFinder";
import {ResponseList} from "./ResponseList";

export const Responses = () => {
    useFinderMode();

    return (
        <LayoutFinder
            parents={[
                {
                    title: 'Home',
                    url: '/'
                },
                {
                    title: 'Stories',
                    url: '/stories'
                }
            ]}>
            <FinderSection>
                <ResponseList />
            </FinderSection>
        </LayoutFinder>
    )
};
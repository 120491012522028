import React, { useEffect, useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import { EditorContent, LayoutDocument, LayoutSection, LayoutEditor, Widget, WidgetHeader, WidgetBody, LayoutFlex, Layout, Button, ButtonPrimary, ButtonSecondary, ButtongroupInline, LayoutField, FieldLabel, FieldInput, FieldSelect } from "@frontend-components/admin";
import { SchoolClass } from "./SchoolClass.js"
import { setFinderActionMode } from "../state/layout/layout-action";
import { kitFetchExternal } from "@kulturit/header-state";
import { getSchool } from "../school/School.js";
import "./School.scss";

export const School = () => {

    const dispatch = useDispatch();

    const {schoolId} = useParams();

    const cachedSchools = useSelector(state => state.school.schools)

    const [school, setSchool] = useState(null);
    const [actorStatus, setActorStatus] = useState(null);
    const [schoolClasses, setSchoolClases] = useState(null);
    const [selectedScoolClass, setSelectedSchoolClass] = useState(null);
    const [newClass, setNewClass] = useState(null);

    const [teachers, setTeachers] = useState(null);

    const grades = [{ value: "1", label: "Årskurs 1" }, { value: "2", label: "Årskurs 2" }, { value: "3", label: "Årskurs 3" },
    { value: "4", label: "Årskurs 4" }, { value: "5", label: "Årskurs 5" }, { value: "6", label: "Årskurs 6" },
    { value: "7", label: "Årskurs 7" }, { value: "8", label: "Årskurs 8" }, { value: "9", label: "Årskurs 9" }]

    
    function extractSchoolClasses(json, school) {
        let result = json.map(sc => (
            {
                key: sc.id,
                name: sc.name,
                grade: sc.grade,
                title: sc.grade + "-" + sc.name,
                school: Object.assign({}, school),
                teachers: extractTeachers(sc.teachers),
                hasStudents: sc.hasStudents,
                actorStatus: sc.actorStatus,
                unselect: () => loadClasses(school) // Note: LoadClasses also sets selected to null
            }
        ));
        return result;
    }

    function loadScoolAndClasses(ac) {
        let key = schoolId; 
        getSchool(key, dispatch, cachedSchools)
            .then(school => {
                setSchool(school);
                loadClasses(school, ac); 
            })
            .catch(err => {
                console.log(err);
                setSchool(null);
            });
    }

    function loadClasses(school, ac) {
        let key = school.key;
        kitFetchExternal(`/minne-api/schools/school/${key}/school_classes`, (ac) ? { signal: ac.signal } : {})
            .then(json => {
                let schoolClasses = extractSchoolClasses(json.schoolClasses, school);
                setSchoolClases(schoolClasses);
                setSelectedSchoolClass(null);
                setActorStatus(json.actorStatus);
            })
            .catch(err => console.log(err));
    }

    function extractTeachers(json) {
        let result = json.map(t => (
            {
                key: t.ekultur_id,
                name: t.name,
                email: t.email,
                personnummer: t.personnummer,
                isCurrentActor: t.isCurrentActor
                // unselect: () => setSelectedTeacher(null),
                // remove: () => removeTeacher(sc.id)
            }
        ));
        return result;
    }

    function loadTeachers(ac) {
        let key = schoolId;
        kitFetchExternal(`/minne-api/schools/school/${key}/teachers`, (ac) ? { signal: ac.signal } : {})
            .then(json => {
                let teachers = extractTeachers(json.teachers);
                setTeachers(teachers);
            })
            .catch(err => console.log(err));
    }

    function addSchoolClass(schoolClass) {
        let key = school.key;
        kitFetchExternal(`/minne-api/schools/school/${key}/school_class`, {
            method: 'POST',
            body: JSON.stringify(schoolClass)
        }).then(() => {
            loadClasses(school);
        }
        ).catch(err => console.log(err));
    }

    function removeSchoolClass(schoolClassId) {
        let key = school.key;
        kitFetchExternal(`/minne-api/schools/school/${key}/school_class/${schoolClassId}`, {
            method: 'DELETE'
        }).then(() => {
            loadClasses(school);
        }
        ).catch(err => console.log(err));
    }

    function initializeNewClass() {
        setNewClass({ name: "", grade: "4", multiGrade: false, valid: false });
    }

    function updateNewClassName(newName) {
        let updatedNewClass = { ...newClass, name: newName };
        updatedNewClass = { ...updatedNewClass, valid: isValidNewClass(updatedNewClass) }
        setNewClass(updatedNewClass);

    }

    function updateNewClassGrade(newGrade) {
        let updatedNewClass = { ...newClass, grade: newGrade };
        updatedNewClass = { ...updatedNewClass, valid: isValidNewClass(updatedNewClass) }
        setNewClass(updatedNewClass);
    }

    function isValidNewClass(schoolClass) {
        let valid = schoolClass.name !== null && schoolClass.name !== "";
        if (valid) {
            valid = schoolClasses.every((sc) => { return !((sc.grade === schoolClass.grade) && (sc.name === schoolClass.name)) });
        }
        return valid
    }

    function addNewClass() {
        if (newClass.valid) {
            addSchoolClass(newClass);
            setNewClass(null);
        }
    }

    function clearNewClass() {
        setNewClass(null);
    }

    function handleSchoolClassSelect(e, schoolClass) {
        if (!e.target.classList.contains("admin-button__icon") && schoolClass.actorStatus.isTeacherInSchoolClass) {
            setSelectedSchoolClass(schoolClass);
        }
    }

    function handleRemoveSchoolClass(e, schoolClass) {
        e.preventDefault();
        removeSchoolClass(schoolClass.key);
    }

    useEffect(() => {
        dispatch(setFinderActionMode());
        const ac = new AbortController();
        loadScoolAndClasses(ac);
        loadTeachers(ac);
        return () => ac.abort();
    }, []);

    return (
        <LayoutEditor
            parents={[
                {
                    title: 'Home',
                    url: '/'
                },
                {
                    title: 'Schools',
                    url: '/schools'
                },
                { 
                    title: `Kommun ${(!!school)?school.municipalityId:""}`,
                    url: `/schools/municipality/${(!!school)?school.municipalityId:""}`
                }
            ]}>
            <EditorContent>
                <LayoutDocument menu={null}>
                    {!!selectedScoolClass &&
                        <SchoolClass schoolClass={selectedScoolClass}></SchoolClass>
                    } {!selectedScoolClass && !!school &&
                        <div>
                           
                            <LayoutSection title={`Klasser på ${school.title}`}>
                                <LayoutFlex>
                                    {!!schoolClasses && schoolClasses.map((schoolClass) =>
                                        <Layout key={schoolClass.key}>
                                            <div title="Klicka för att visa elever i klassen">
                                                <Widget onClick={e => handleSchoolClassSelect(e, schoolClass)}
                                                    style={{ backgroundColor: schoolClass.actorStatus.isTeacherInSchoolClass ? "white" : "lightgrey" }}
                                                >
                                                    <WidgetHeader style={{ height: "30px" }} >
                                                        <h1><span>{schoolClass.title}</span>
                                                            {schoolClass.actorStatus.isTeacherInSchoolClass && !schoolClass.hasStudents &&
                                                                <Button
                                                                    style={{ float: "right" }}
                                                                    onClick={e => handleRemoveSchoolClass(e, schoolClass)}
                                                                    icon="delete" >
                                                                </Button>
                                                            }
                                                        </h1>
                                                    </WidgetHeader>
                                                    <WidgetBody>
                                                        <p>Lärare:&nbsp;{!!schoolClass.teachers && schoolClass.teachers.map((t, i, a) => <span key={t.key} className="name-list">{t.name}{(i < a.length - 1) ? "" : ""}</span>)}</p>
                                                    </WidgetBody>
                                                </Widget>
                                            </div>
                                        </Layout>
                                    )}
                                    <Widget>
                                        {!newClass &&
                                            <ButtonPrimary  title="Lägg till ny klass" icon="add" disabled={!actorStatus || !actorStatus.isTeacherAtSchool} onClick={initializeNewClass}></ButtonPrimary>
                                        }
                                        {!!newClass &&
                                            <LayoutField>
                                                <FieldLabel label="Årskurs"></FieldLabel>
                                                <FieldSelect
                                                    title={"Årskurs"}
                                                    options={grades}
                                                    multiple={newClass.multiGrade}
                                                    value={newClass.grade}
                                                    onChange={updateNewClassGrade}
                                                    required={true}></FieldSelect>
                                                <FieldLabel label="Klassnamn"></FieldLabel>
                                                <FieldInput
                                                    title={"Klassnamn"}
                                                    value={newClass.name}
                                                    onChange={updateNewClassName}
                                                    required={true}></FieldInput>
                                                <ButtongroupInline>
                                                    <ButtonPrimary disabled={!newClass.valid} title="Spara" icon="done" onClick={addNewClass}></ButtonPrimary>
                                                    <ButtonSecondary title="Avbryt" icon="close" onClick={clearNewClass}></ButtonSecondary>
                                                </ButtongroupInline>

                                            </LayoutField>
                                        }
                                    </Widget>
                                </LayoutFlex>

                            </LayoutSection>
                            <LayoutSection title={`Lärare på ${school.title}`}>
                                <LayoutFlex>
                                    {!!teachers && teachers.map((teacher) =>
                                        <Layout key={teacher.key}>
                                            <Widget style={{ backgroundColor: teacher.isCurrentActor ? "white" : "lightgrey" }}>
                                                <WidgetHeader style={{ height: "30px" }}>
                                                    <h1>{teacher.name}</h1>
                                                </WidgetHeader>
                                                <WidgetBody>
                                                    <p>{teacher.email}</p>
                                                    <p>#{teacher.key}#</p>
                                                </WidgetBody>
                                            </Widget>
                                        </Layout>
                                    )}
                                </LayoutFlex>
                            </LayoutSection>
                        </div>
                    }
                </LayoutDocument>
            </EditorContent>
        </LayoutEditor >
    );

}
import React from 'react';
import {useFinderMode} from "../layout/hooks/useFinderMode";
import {FinderSection} from "@frontend-components/admin";
import LayoutFinder from "@frontend-components/admin/es/components/Finder/LayoutFinder";
import {OwnerList} from "./OwnerList";

export const Owners = () => {
    useFinderMode();

    return (
        <LayoutFinder
            parents={[
                {
                    title: 'Home',
                    url: '/'
                },
                {
                    title: 'Edit owners',
                    url: '/owners'
                }
            ]}>
            <FinderSection>
                <OwnerList />
            </FinderSection>
        </LayoutFinder>
    );
};

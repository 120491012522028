import React from 'react';
import {Component} from 'react';
import {connect} from "react-redux";
import querystringify from "querystringify";
import {selectOwnerById} from "@kulturit/header-state";
import PropTypes from 'prop-types';
import {push} from "connected-react-router";
import {setFinderMode} from "../state/layout/layout-action";

class Home extends Component {

    static propTypes = {
        selectOwner: PropTypes.func.isRequired,
        redirect: PropTypes.func.isRequired,
        setFinderMode: PropTypes.func.isRequired,
        ownerId: PropTypes.number,
    };

    componentDidMount() {
        const {
            selectOwner,
            ownerId,
            redirect,
            setFinderMode,
        } = this.props;

        setFinderMode();

        if(ownerId) {
            redirect(`/museums/${ownerId}`)
        }

        let parsed = querystringify.parse(this.props.location.search);

        if(parsed.museum_id) {
            selectOwner(parseInt(parsed.museum_id));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            access,
            redirect
        } = this.props;
        const {access: prevAccess} = prevProps;

        if(!prevAccess.museums.length && 1 === access.museums.length) {
            // redirect(`/somewhere/where/museumdata/is`)
        }
    }

    render() {
        return (<section className="info-text">
            <h1>Hej Lärare 👋</h1> 
            <h2>Här lägger du upp din klass och dina elever</h2>
            <p>Detta är din ingång som lärare för att... (Fin text som Jörgen skriver kommer här.)</p>
        </section> );
    }
}

const mapStateToProps = state => ({
    ownerId: state.header.owner.uniqueId,
});

const actions = {
    selectOwner: ownerId => selectOwnerById(ownerId),
    redirect: path => push(path),
    setFinderMode
};

export default connect(mapStateToProps, actions)(Home)

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { kitFetchExternal } from "@kulturit/header-state";

import { setFinderActionMode } from "../state/layout/layout-action";
import { getTopicItemTypes } from "../state/topic/topic-action";
import { EditorContent, LayoutDocument, LayoutSection, LayoutEditor } from "@frontend-components/admin";
import { Municipality } from "./Municipality.js"
import "./Schools.scss";

export const Schools = () => {

    const { t, i18n } = useTranslation();

    const { municipalityId } = useParams();

    const dispatch = useDispatch();


    const [documentMenu, setDocumentMenu] = useState([]);
    const [selectedMunicipality, setSelectedMunicipality] = useState(null);



    function extractMunicipalities(json) {
        return json.filter(entity => entity.entityType === "Place").map(entity => {
            let code = entity.properties["place.code"][0].value;
            return {
                key: entity.uuid,
                title: entity.properties["entity.name"][0].value.sv,
                code: code,
                countyUUID: entity.properties["place.isPartOf"][0].value //,
            }
        });
    }

    function extractCounties(json) {
        return json.filter(entity => entity.entityType === "Place").map(entity => (
            {
                uuid: entity.uuid,
                title: entity.properties["entity.name"][0].value.sv,
                code: entity.properties["place.code"][0].value,
            }
        ));
    }


    function buildMenu(counties, municipalities) {
        let municipalitiesPerCounty = new Map(counties.map(c => [c.uuid, { county: c, municipalities: [] }]));
        for (const m of municipalities) {
            municipalitiesPerCounty.get(m.countyUUID).municipalities.push(m);
        }
        let result = [];
        for (const [, c_ms] of municipalitiesPerCounty) {
            let children = [];
            for (const m of c_ms.municipalities) {
                let selected = false;
                if (!!selectedMunicipality) {
                    selected = m.code === selectedMunicipality.code;
                } else if (!!municipalityId && municipalityId === m.key) {
                    selected = true;
                    setSelectedMunicipality(m);
                }
                children.push(
                    {
                        title: m.title,
                        onClick: () => setSelectedMunicipality(m),
                        selected: selected
                    }
                );
            };

            result.push(
                {
                    title: c_ms.county.title,
                    children: children
                }
            );
        };
        return result;
    }

    function loadMunicipalitiesAndBuildMenu(ac) {
        let municipalitiesFuture = kitFetchExternal(`/kulturnav-api/list/b96a2c92-971e-464d-9f6a-1a0b82a44800`, (ac) ? { signal: ac.signal } : {});
        let countiesFuture = kitFetchExternal(`/kulturnav-api/list/172a20f7-bb49-4947-a653-e53df6c24259/0/25`, (ac) ? { signal: ac.signal } : {});
        let actorStatusFuture = kitFetchExternal('/minne-api/schools/actor_status', (ac) ? { signal: ac.signal } : {});
        Promise.all([municipalitiesFuture, countiesFuture, actorStatusFuture]).then(([mjson, cjson, ajson]) => {
            let municipalities = extractMunicipalities(mjson);
            let counties = extractCounties(cjson);
            let menu = buildMenu(counties, municipalities);
            setDocumentMenu(menu);
        }).catch(err => console.log(err));
    }


    useEffect(() => {
        dispatch(setFinderActionMode());
        dispatch(getTopicItemTypes());
        setSelectedMunicipality(null);
        const ac = new AbortController();
        loadMunicipalitiesAndBuildMenu(ac);
        return () => ac.abort();
    }, []);

    return (
        <LayoutEditor

        parents={(!selectedMunicipality)?
        [
            {
                title: t('app.schools.breadcrumbs.home'),
                url: '/'
            },
            {
                title: t('app.schools.breadcrumbs.municipalities'),
                url: '/schools'
            }
        ] : [
            {
                title: t('app.schools.breadcrumbs.home'),
                url: '/'
            },
            {
                title: t('app.schools.breadcrumbs.municipalities'),
                url: '/schools'
            },
            {
                title: selectedMunicipality.title,
                url: `/schools/municipality/${selectedMunicipality.key}`
            }       
        ]}


            
            title={t('app.schools.breadcrumbs.selectSchool')} 
            
            status={"draft"} 
            
            action={[{
                "label": "Save"
            }]}

            >

            <EditorContent>

                <LayoutDocument menu={documentMenu}>
                    {!!selectedMunicipality &&
                        <Municipality municipality={selectedMunicipality}></Municipality>
                    }
                     {!selectedMunicipality &&
                        <LayoutSection title={t('app.schools.texts.selectCountyAndMunicipality')}>
                        </LayoutSection>
                    }
                </LayoutDocument>
            </EditorContent>

        </LayoutEditor >
    );
}
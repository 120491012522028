import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setEditorMode} from "../state/layout/layout-action";
import {EditorContent, EditorPreview, LayoutDocument, LayoutEditor} from '@frontend-components/admin';
import './site.css'
import {SelectRender} from "./siteRenderHtml/SelectRender";
import {renderSection} from "./siteRenderHtml/RenderSection";
import {UploadFirstPagePicture} from "./siteRenderHtml/UploadFirstPagePicture";
import {kitFetchExternal} from "@kulturit/header-state";

export const Site = () => {
    const dispatch = useDispatch();
    const [globalValues, setGlobalValues] = useState(null);
    const [currentSection, setCurrentSection] = useState('header');

    useEffect(() => {
        dispatch(setEditorMode());
        kitFetchExternal(`/minne-api/site/global_values`)
            .then(json => setGlobalValues(json))
            .catch(err => console.log(err));
    }, []);

    if(!globalValues) {
        return null
    }

    const sections = {
        header: {
            searchBannerText: globalValues.searchBannerText,
            topBanner: globalValues.topBanner
        },
        frontPage: {
            firtsPageWelcomeHeader: globalValues.firtsPageWelcomeHeader,
            firtsPageWelcomeText: globalValues.firtsPageWelcomeText
        },
        infoAbout: {
            infoAbout: globalValues.infoAbout
        },
        infoHelp: {
            infoHelp: globalValues.infoHelp
        },
        infoTerms: {
            infoTerms: globalValues.infoTerms
        },
        infoLicenses: {
            infoLicenses: globalValues.infoLicenses
        },
        infoContact: {
            infoContact: globalValues.infoContact
        }


    };

    const documentMenu = Object.keys(sections).map(section => ({
        title: section,
        onClick: () => setCurrentSection(section),
        selected: section === currentSection
    }));

    return (
        <LayoutEditor
            button={{
                title: 'Save',
                onClick: () => kitFetchExternal('/minne-api/site/global_values', {
                    method: 'PUT',
                    body: JSON.stringify(globalValues)})
            }}
            parents={[
                {
                    title: 'Home',
                    url: '/'
                },
                {
                    title: 'Site',
                    url: '/site'
                }

            ]}>
            <EditorPreview expanded={true}>
                <SelectRender globalValues={globalValues} currentSection={currentSection} />
            </EditorPreview>
            <EditorContent>
                <LayoutDocument menu={documentMenu}>
                    {renderSection(globalValues, setGlobalValues, currentSection, sections)}
                    <UploadFirstPagePicture currentSection={currentSection}/>
                </LayoutDocument>
            </EditorContent>
        </LayoutEditor>
    );

    // return (
    //     <div>
    //         <pre>
    //             {JSON.stringify(globalValues, null, 2)}
    //         </pre>
    //     </div>
    // )
};
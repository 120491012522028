import React from "react";
import {UseOwnerTypes} from "../owners/useOwnerTypes";
import FieldSelect from "@frontend-components/admin/es/components/Field/FieldSelect";
import {LayoutField} from "@frontend-components/admin";

export const OwnerTypeSelect = ({owner_type_id, onChange}) => {
    const ownerTypes = UseOwnerTypes();

    const MapOwnerTypes = ownerTypes => {
        if(ownerTypes){
            return ownerTypes.map(ownerType => ({value: ownerType.id, label: ownerType.name}))
        } else {
            return []
        }
    };

    return (
        <LayoutField>
            <div className='admin-field-select__label admin-field.field--select'>Owner type</div>
            <FieldSelect
                value={owner_type_id}
                options={MapOwnerTypes(ownerTypes)}
                onChange={onChange}
            />
        </LayoutField>
    )
};
import React from "react";

export const UploadFirstPagePicture = ({currentSection}) => {
    if (currentSection === 'frontPage') {
        return <div>
            <br/><p>HER SKAL OPPLASTING AV MEDIA INN</p>
        </div>
    } else {
        return null
    }
};
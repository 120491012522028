import React, {Component} from 'react';
import PropTypes from "prop-types";
import {LayoutAdmin} from '@frontend-components/admin'
import {connect} from "react-redux";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class Layout extends Component {
    static propTypes = {
        app: PropTypes.shape({
            title: PropTypes.string.isRequired,
            subtitle: PropTypes.string.isRequired,
            button: PropTypes.object
        }).isRequired,
        user: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
        onSearch: PropTypes.func
    };

    // render() {
    //     return (
    //         <LayoutAdmin
    //             {...this.props}
    //             user={this.props.user}
    //             menu={this.createMenu()}
    //             button={this.createButton()}
    //         />
    //     )
    // }


    render() {
        const {
            t,
            authz
        } = this.props;
        const user = {
            ...this.props.user,
            apps: this.props.user.apps ? this.props.user.apps : [],
            owners: this.props.user.owners ? this.props.user.owners : [],
        };
        return (
            <LayoutAdmin
                {...this.props}
                app={{...this.app,title:(authz.isAdmin)?t('app.titles.admin'):t('app.titles.schools')}}
                user={user}
                menu={this.createMenu()}
                button={this.createButton()}
            />
        )
    }

    createButton() {
        const {
            t,
            authz
        } = this.props;

        if(authz.isAdmin) {
            return {
                title: t('menu.new'),
                children: [
                    {
                        title: t('menu.newOwner'),
                        url: '/owner/new'
                    }
                ]
            }
        } else {
            return null
        }
    }

    createMenu() {
        const {
            t,
            authz
        } = this.props;

        if(authz.isAdmin) {
            return [
                {
                    title: t('menu.site'),
                    url: '/site',
                },
                {
                    title: t('menu.responses'),
                    url: t('/stories')
                },
                {
                    title: t('menu.owners'),
                    url: '/owners',
                },
                {
                    title: t('menu.permissions'),
                    url: '/permissions'
                },
                {
                    title: t('menu.schoolFinder'),
                    url: '/schools',
                },
                {
                    title: t('menu.yourSchools'),
                    url: '/schools',
                }
            ]
        } else {
            return [
                {
                    title: t('menu.schoolFinder'),
                    url: '/schools',
                },
                {
                    title: t('menu.yourSchools'),
                    url: '/schools',
                }
            ]
        }
    }
}

const mapStateToProps = state => ({
    ...state.header,
    ...state.layout,
});


const actions = {
    onSearch: q => console.log(q)
};

export default compose(
    connect(mapStateToProps, actions),
    withTranslation()
)(Layout);
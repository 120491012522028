import React, {Component} from 'react';
import './App.css';
import {BrowserRouter as Router} from "react-router-dom";
import Layout from "./layout/AdminLayout";
import AppRoutes from "./routing/AppRoutes";
import Authenticator from "@kulturit/header-state/es/auths/Authenticator";

class App extends Component {
    render() {
        return (
                <Router>
                    <Authenticator appId={process.env.REACT_APP_ID} app={{ version: '1.0', updatedAt: '24.10.2019' }} >
                        <main>
                            <Layout 
                                theme={{
                                "primaryColor": "#ffdf86",
                                "lightColor": "#de6773",
                                "darkColor": "#008fad"
                                // "primaryColor": "#d94353",
                                // "lightColor": "#ee7e8f",
                                // "darkColor": "#ae3552"
                            }}>
                                <AppRoutes />
                            </Layout>
                        </main>
                    </Authenticator>
                </Router>
        );
    }
}

export default App;

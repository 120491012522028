import React, {forwardRef, useState} from 'react';
import {useHistory} from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {IconButton, Paper} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import {kitFetchExternal} from "@kulturit/header-state";
import * as classes from "rxjs/internal-compatibility";
import TextField from "@material-ui/core/TextField";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {useOwners} from "./useOwners";

export const OwnerList = () => {

    const history = useHistory();
    const [orderBy, setOrderBy] = useState('id');
    const [descending, setDescending] = useState(true);
    const [start, setStart] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [localQuery, setLocalQuery] = useState("");
    const [query, setQuery] = useState("");
    const headersCells = ['name', 'identifier', 'owner_type_id', 'web', 'email'];

    const {owners, count} = useOwners({
        orderBy: orderBy,
        descending: descending,
        start: start,
        rowsPerPage: rowsPerPage,
        query: query
    });

    const sortHandler = (headerCell) => {
        if(orderBy === headerCell) {
            setDescending(!descending);
        } else {
            setOrderBy(headerCell);
            setDescending(true);
        }
    };

    const rowsPerPageHandler = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const changePageHandler = newPage => {
        if (page < newPage) {
            setStart(start + rowsPerPage);
        } else {
            setStart(start - rowsPerPage);
        }

        setPage(newPage);
    };

    const changeQuery = (event) => {
        setLocalQuery(event.target.value);
    };
   const searchHandler = (event) => {
        console.log(event);
        setQuery(localQuery);
        event.preventDefault();
    };
    return (
        <Paper>
            <Toolbar>
                <Tooltip title="Search">
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Search">
                    <form className={classes.root} noValidate autoComplete="off" onSubmit={searchHandler}>
                        <TextField id="standard-search" label="Search field" type="search" color="secondary" value={localQuery} onChange={(event) => changeQuery(event)}/>
                    </form>
                </Tooltip>
                <Tooltip placement="right" title="Filter">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Add owner">
                    <IconButton>
                        <AddIcon onClick={() => history.push('/owner/new/')}/>
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            {headersCells.map(headerCell => (
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === headerCell}
                                        direction={descending ? "desc" : "asc"}
                                        onClick={() => sortHandler(headerCell)}
                                    >
                                        {headerCell}
                                    </TableSortLabel>
                                </TableCell>
                            ))}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {owners.map(owner => (
                            <TableRow key={owner.id}>
                                <TableCell>
                                    <IconButton>
                                        <EditIcon onClick={() => {history.push('/owners/' + owner.id)}}/>
                                    </IconButton>
                                    <IconButton>
                                        <DeleteIcon onClick={() => {
                                            const confirmDelete = window.confirm(
                                                "Are you sure you want to delete " + owner.name + "?");
                                                if(confirmDelete === true){
                                                    kitFetchExternal('/minne-api/owners/remove/' + owner.id, {
                                                        method: 'DELETE'
                                                    })
                                                }
                                        }}/>
                                    </IconButton>
                                </TableCell>
                                <TableCell>{owner.name}</TableCell>
                                <TableCell component="th" scope="row">{owner.identifier}</TableCell>
                                <TableCell>{owner.owner_type ? owner.owner_type.name : false}</TableCell>
                                <TableCell>{owner.web}</TableCell>
                                <TableCell>{owner.email}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Rows per page"
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={rowsPerPageHandler}
                component="div"
                count={count}
                page={page}
                onChangePage={(event, newPage) => changePageHandler(newPage)}
            />
        </Paper>
    );
};

import {TopBannerTextHtmlRender} from "./TopBannerText";
import {FirstPageHtmlRender} from "./FirstPage";
import {InfoAboutHtmlRender} from "./InfoAbout";
import {InfoHelpHtmlRender} from "./InfoHelp";
import {InfoTermsHtmlRender} from "./InfoTerms";
import {InfoLicensesHtmlRender} from "./InfoLicenses";
import {InfoContactHtmlRender} from "./InfoContact";
import React from "react";

export const SelectRender = ({currentSection, globalValues}) => {
    if (currentSection === 'header') {
        return <TopBannerTextHtmlRender globalValues={globalValues} />
    } else if(currentSection === 'frontPage') {
        return <FirstPageHtmlRender globalValues={globalValues} />
    } else if(currentSection === 'infoAbout') {
        return <InfoAboutHtmlRender globalValues={globalValues} />
    } else if(currentSection === 'infoHelp') {
        return <InfoHelpHtmlRender globalValues={globalValues} />
    } else if(currentSection === 'infoTerms') {
        return <InfoTermsHtmlRender globalValues={globalValues}/>
    } else if(currentSection === 'infoLicenses') {
        return <InfoLicensesHtmlRender globalValues={globalValues}/>
    } else if(currentSection === 'infoContact') {
        return <InfoContactHtmlRender globalValues={globalValues} />
    }
    else {
        return null
    }
};
import React, {useEffect, useState} from 'react';
import {useFinderMode} from "../layout/hooks/useFinderMode";
import {LayoutEditor} from "@frontend-components/admin";
import {useHistory, useParams} from 'react-router-dom';
import {EditOwnerData} from "./EditOwnerData";
import {useDispatch} from "react-redux";
import './editOwner.css'
import {kitFetchExternal} from "@kulturit/header-state";

export const EditOwner = () => {
    const [owner, setOwner] = useState();
    const dispatch = useDispatch();
    const {ownerId} = useParams();
    const history = useHistory();
    useFinderMode();

    useEffect(() => {
        kitFetchExternal(`/minne-api/owners/${ownerId}`)
            .then(json => {setOwner(json)})
            .catch(err => console.log("Owner fetch error: " + err));

    },  [dispatch, setOwner, ownerId]);

    if(!owner) {
        return null
    }

    return (
        <LayoutEditor
            // button={{
            //     title: 'Delete',
            //     url: '/owners',
            //     onClick: () => {
            //         kitFetch(`/minne-api/owners/remove/${ownerId}`, {
            //             method: 'DELETE',
            //             body: JSON.stringify(owner)
            //         })
            //     }
            // }}
            button={{
                title: 'Save',
                onClick: () => {
                    const payload = {
                        id: owner.id,
                        identifier: owner.identifier,
                        name: owner.name,
                        logo: owner.logo,
                        owner_type_id: owner.owner_type_id,
                        options: owner.options,
                        web: owner.web,
                        email: owner.email,
                        feedback_email: owner.feedback_email,
                        description: owner.description
                    };
                    kitFetchExternal('/minne-api/owners/put_owner', {
                        method: 'POST',
                        body: JSON.stringify(payload)
                    });
                    history.push('/owners')
                }
            }}
            parents={[
                {
                    title: "Home",
                    url: "/"
                },
                {
                    title: "Edit owners",
                    url: "/owners"
                },
                {
                    title: owner.name,
                    url: "/owners/" + ownerId
                }
            ]}>
            <div className="edit-owner-fields">
                <EditOwnerData
                    owner={owner}
                    setOwner={setOwner}
                />
            </div>
        </LayoutEditor>
    );
};
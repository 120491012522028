export class ResponseItem {
    constructor(response, topicItem) {
        this.key = topicItem.key;
        this.typeId = topicItem.topic_item_type_id;
        this.displayValue= getDisplayValueFromResponse(response, topicItem);
        this.sortIndex= topicItem.display_sort_index;
        let labelAndDescription = getLabelAndDecriptionFromTopicItem(topicItem);
        this.label=labelAndDescription.label;
        this.description = labelAndDescription.description;
        if (topicItem.options && topicItem.options.length > 0) {
            let options = {};
            for (let option of topicItem.options) {
                options[option.id]=getLocalizedObject(option.labels);
            }
            this.options = options;
        } else {
            this.options={};
        }
    }
}

function getDisplayValueFromResponse(response, topicItem) {
    let isTentativeMemory = response && response.isTentative;
    let result = '';
    if (isTentativeMemory) {
        result = (response[topicItem.key] || {}).display_value || '';
    } else {
        let response_value = response.response_values[topicItem.key];
        if (response_value && response_value.display_value) {
            result = getLocalizedOrAsIs(response_value.display_value);
        }
    }
    return result;
};

function getLabelAndDecriptionFromTopicItem(topicItem) {
    return getLocalizedOrAsIs(topicItem.labels, { label: '', description: null });
};

function getLocalizedOrAsIs(o, defaultValue = '') {
    let result;
    if (o !== null && typeof o === 'object') {
        var locale = "sv_se";
        var defaultLocale = "sv_se";
        result = o[locale] || o[defaultLocale] || Object.values(o)[0] || defaultValue;
    } else {
        result = o || defaultValue;
    }

    return result;
};

function getLocalizedObject(o) {
    if (o !== null) {
        let locale = "sv_se";
        let defaultLocale = "sv_se";
        return o[locale] || o[defaultLocale] || {};
    } else {
        return {};
    }
};
/* Current topic Item types
"1";"Input";TRUE
"2";"Textarea";TRUE
"3";"Radio";TRUE
"4";"Checkbox";TRUE
"5";"Select";TRUE
"6";"Single date";TRUE
"7";"Date range";TRUE
"8";"Form help text";TRUE
"9";"Media";TRUE
"10";"WYSIWYG";TRUE
"11";"Title";TRUE
"12";"Subjects";
"13";"Coordinate";TRUE
"14";"Coordinates";TRUE
"15";"YouTube";TRUE
"16";"SoundCloud";TRUE
*/
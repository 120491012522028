import {FieldInput, LayoutField} from "@frontend-components/admin";
import React from "react";

export const TextInput = ({title, help, errors, value, onChange}) => {

    return (
        <LayoutField title={title} help={help} errors={errors} >
            <FieldInput
                value={value}
                required={true}
                onChange={(value, event) => onChange(value, event.target.checkValidity())}
            />
        </LayoutField>
    )
};

import React from "react";
import parse from "html-react-parser";

export class FirstPageHtmlRender extends React.Component {
    render () {
        const {globalValues} = this.props;

    return (
        <div className='minner-preview'>
            <div align="center">
                <input className='heading-searchbar' value={parse(globalValues['searchBannerText'].sv_se)}/>
            </div>
            <div className='top-banner'>
                <div className='top-banner-area'>
                    <p className='top-banner-greeting'>
                        {globalValues['topBanner'].sv_se}
                    </p>
                </div>
            </div>
            <img className='front-page-picture' src="https://dev.minnen.se/assets/img/header.sv_se.sv_se.2.0.3.jpg"
                 alt="There should be something here..."/>
            <div className='welcome-header'>
                {parse(globalValues['firtsPageWelcomeHeader'].sv_se)}
            </div>
            <div className='welcome-text'>
                {parse(globalValues['firtsPageWelcomeText'].sv_se)}
            </div>
        </div>
    )

  }
}
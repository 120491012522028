import React from "react";
import parse from "html-react-parser";

export class InfoTermsHtmlRender extends React.Component {
    render () {
        const {globalValues} = this.props;

    return (
        <div className='minner-preview'>
            <div align="center">
                <input className='heading-searchbar' value={parse(globalValues['searchBannerText'].sv_se)}/>
            </div>
            <div className='top-banner'>
                <div className='top-banner-area'>
                    <div className='top-banner-greeting'>
                        {globalValues['topBanner'].sv_se}
                    </div>
                </div>
            </div>
            <div className='info-terms'>
                {parse(globalValues['infoTerms'].sv_se)}
            </div>
        </div>
    )
    }
}
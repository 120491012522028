import React, {useEffect, useState} from "react";
import LayoutEditor from "@frontend-components/admin/es/components/Editor/LayoutEditor";
import {FinderSection} from "@frontend-components/admin";
import {useFinderMode} from "../layout/hooks/useFinderMode";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {kitFetchExternal} from "@kulturit/header-state";

export const AssignPermissions = () => {
    const [owner, setOwner] = useState();
    const dispatch = useDispatch();
    const {ownerId} = useParams();
    useFinderMode();

    useEffect(() => {
        kitFetchExternal(`/minne-api/owners/${ownerId}`)
            .then(json => {
                setOwner(json)
            })
            .catch(err => console.log("Owner fetch error: " + err));

    },  [dispatch, setOwner, ownerId]);

    if(!owner) {
        return null
    }

    return (
        <LayoutEditor
            parents={[
                {
                    title: 'Home',
                    url: '/'
                },
                {
                    title: 'Permission owners',
                    url: '/permissions'
                },
                {
                    title: owner.name,
                    url: '/permissions/' + ownerId
                }
            ]}>
            <FinderSection>

            </FinderSection>
        </LayoutEditor>
    )
};
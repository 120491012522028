import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { kitFetchExternal } from "@kulturit/header-state";
import { Widget, WidgetHeader, WidgetBody, LayoutSection, LayoutFlex, Layout, Button } from "@frontend-components/admin";
import { Memory } from "./Memory.js";
import { ResponseItem } from "../Responses/ResponseItem";
import "./School.scss";
import { getTopics } from "../topics/Topic.js";

export const Student = ({ student }) => {

    const dispatch = useDispatch();

    //const topicItemTypes = useSelector(state => state.topic.topicItemTypes)
    const cachedTopics = useSelector(state => state.topic.topics)

    const [memories, setMemories] = useState(null);

    const [selectedMemory, setSelectedMemory] = useState(null);


    function extractMemories(json) {
        let result = json.map(m => (
            {
                key: m.uuid,
                topicId: m.response.topic_id,
                response: m.response,
                status: m.statu,
                student: Object.assign({}, student),
                unselect: () => setSelectedMemory(null)
            }
        ));
        return result;
    }

   

    function processMemories(memories, topics) {
        let result = memories.map(memory => {
            let responseItems = [];
            let topic = topics.get(memory.topicId);
            let topicItems = topic.items;
            for (let topicItem of topicItems) {
                responseItems.push(new ResponseItem(memory.response, topicItem));
            };
            memory.topic = Object.assign({}, topic);
            memory.response.responseItems = responseItems;
            memory.response.slogan = getSlogan(memory.response);
            if (memory.response.dms_id) {
                memory.imageUrl = `http://dms07.dimu.org/image/${memory.response.dms_id}`;
            } else {
                let topicImageUrl = memory.topic.image_url;
                if (topicImageUrl) {
                    memory.imageUrl = topicImageUrl;
                }
            }

            return memory;
        })
        return result;
    }

    function getSlogan(resp) {
        let slogan = resp.slogan;
        if (!slogan || slogan === "") {
            slogan = "";
            let lowestSortIndexFound = 99999;
            for (let responseItem of resp.responseItems) {
                if (isTextResponseItem(responseItem.typeId) && lowestSortIndexFound > responseItem.sortIndex) {
                    if (responseItem.displayValue && responseItem.displayValue !== "") {
                        slogan = responseItem.displayValue;
                        lowestSortIndexFound = responseItem.sortIndex;
                    }

                }
            }
        }
        return slogan;
    }

    function isTextResponseItem(topicItemTypeId) {
        // Note! WYSIWYG = 10 might be interestnig but also problematic
        return [1, 2].includes(topicItemTypeId);
    }


    function loadMemories(ac) {
        kitFetchExternal(`/minne-api/schools/school/${student.schoolClass.school.key}/school_class/${student.schoolClass.key}/student/${student.key}/memories`, (ac) ? { signal: ac.signal } : {})
            .then(json => {
                let memories = extractMemories(json.memories);
                getTopics(memories.map(m => m.topicId), dispatch, cachedTopics)
                    .then((topics) => {
                        setSelectedMemory(null);
                        setMemories(processMemories(memories, topics));
                    }).catch(err => {
                        console.log(err);
                    });
            })
            .catch(err => console.log(err));
    }



    function handleSelectMemory(e, memory) {
        if (!e.target.classList.contains("admin-button__icon")) {
            setSelectedMemory(memory);
        }
    }

    useEffect(() => {
        const ac = new AbortController();
        loadMemories(ac);
        return () => ac.abort();
    }, [student]);


    if (!!selectedMemory) {
        return (<Memory memory={selectedMemory}></Memory>);
    } else {
        return (

            <div>
                <div className="header">
                    <Button
                        title={"Visa alla elever i" + student.schoolClass.title}
                        onClick={e => student.unselect()}
                        icon="chevron_left" >
                    </Button>
                </div>
                <LayoutSection title={student.name + " minnen -  redo för publicering"}>
                    <LayoutFlex>
                        {!!memories && memories.filter(memory => !!memory.response.readyForApproval).map((memory) =>
                            <Layout key={memory.key}>
                                <Widget onClick={e => handleSelectMemory(e, memory)}>
                                    <WidgetHeader style={{ height: "30px" }}>
                                        <h1 className="memory"><span>{memory.topic.name}</span> - <span>{memory.response.title}</span>
                                        </h1>
                                    </WidgetHeader>
                                    <WidgetBody>
                                        <img className="memory-preview-thumbnail" src={memory.imageUrl}
                                            alt="test"
                                        />
                                        <p>{memory.response.slogan}</p>
                                    </WidgetBody>
                                </Widget>
                            </Layout>
                        )}
                    </LayoutFlex>
                </LayoutSection>
                <LayoutSection title={student.name + " minnen - under arbete"}>
                    <LayoutFlex>
                        {!!memories && memories.filter(memory => !memory.response.readyForApproval).map((memory) =>
                            <Layout key={memory.key}>
                                <Widget onClick={e => handleSelectMemory(e, memory)}>
                                    <WidgetHeader style={{ height: "30px" }}>
                                        <h1 className="memory"><span>{memory.topic.name}</span> - <span>{memory.response.title}</span>
                                        </h1>
                                    </WidgetHeader>
                                    <WidgetBody>
                                        <img className="memory-preview-thumbnail" src={memory.imageUrl}
                                            alt="test"
                                        />
                                        <p>{memory.response.slogan}</p>
                                    </WidgetBody>
                                </Widget>
                            </Layout>
                        )}
                    </LayoutFlex>
                </LayoutSection>
                <div className="footer">
                    <Button
                        title={"Visa alla elever i" + student.schoolClass.title}
                        onClick={e => student.unselect()}
                        icon="chevron_left" >
                    </Button>
                </div>

            </div>
        );
    }

}
import React from "react";
import { Widget, WidgetHeader, WidgetBody, LayoutSection, LayoutFlex, Layout, LayoutField, ButtongroupInline, Button, ButtonPrimary, ButtonSecondary } from "@frontend-components/admin";
import "./School.scss";
import { kitFetchExternal } from "@kulturit/header-state";

export const Memory = ({ memory }) => {

    //const topicItemTypes = useSelector(state => state.topic.topicItemTypes)

    function publish() {
        let payload = {};
        let url = `/minne-api/schools/school/${memory.student.schoolClass.school.key}/school_class/${memory.student.schoolClass.key}/student/${memory.student.key}/memory/${memory.key}/publish`
        kitFetchExternal(url, {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(() => {
            memory.unselect();
        }
        ).catch(err => console.log(err));
    }

    function handlePublish(event) {
        publish();
    }

    function handleSave(event) {
    
    }


    return (
        <div>
            <div className="header">
                <Button
                    title={"Visa alla minnen för " + memory.student.name}
                    onClick={e => memory.unselect()}
                    icon="chevron_left" >
                </Button>
            </div>
            <LayoutSection>
                <LayoutFlex>

                    <Layout >
                        <Widget>
                            <WidgetHeader style={{ height: "30px" }}>
                                <h1 className="memory"><span>{memory.topic.name}</span> - <span>{memory.response.title}</span>
                                </h1>
                            </WidgetHeader>
                            <WidgetBody>
                                {!!memory.response.responseItems && memory.response.responseItems.map((responseItem) =>
                                    <div key={responseItem.key}>
                                        <h2>{responseItem.label}</h2>


                                        <h3>Value (Type: {responseItem.typeId})</h3>
                                        <p>{responseItem.displayValue}</p>
                                        <br />

                                        {!!responseItem.options &&
                                        <div>
                                        <h3>Options</h3>
                                            {Object.entries(responseItem.options).map(([optionKey, optionValue]) => 
                                                    <p key={optionKey}>{optionKey}:{optionValue.label}</p>)}
                                        </div>
                                        }

                                     
                                    </div>
                                )}
                                <LayoutField>
                                        <ButtongroupInline>
                                            <ButtonPrimary  title="Publicera" icon="done" onClick={handlePublish}></ButtonPrimary>
                                            <ButtonPrimary  title="Skicka till forskning" icon="done" onClick={handleSave}></ButtonPrimary>
                                            <ButtonSecondary title="Avbryt" icon="close" onClick={e => memory.unselect()}></ButtonSecondary>
                                        </ButtongroupInline>
                                </LayoutField>
                            </WidgetBody>
                        </Widget>
                    </Layout>

                </LayoutFlex>
            </LayoutSection>
            <div className="footer">
                <Button
                    title={"Visa alla minnen för " + memory.student.name}
                    onClick={e => memory.unselect()}
                    icon="chevron_left" >
                </Button>
            </div>

        </div>
    );


}
import React, {Component} from 'react';
import Page404 from "../common/Page404";
import {Route, Switch} from "react-router-dom";
import Home from "../layout/Home";
import {Site} from "../site/Site";
import {Schools} from "../schools/Schools";
import {School} from "../schools/School";
import {Owners} from "../owners/owners";
import {EditOwner} from "../owners/EditOwner";
import {NewOwner} from "../owners/NewOwner";
import {PermissionOwners} from "../permissions/PermissionOwners";
import {AssignPermissions} from "../permissions/AssignPermissions";
import {Responses} from "../Responses/Responses"
import PrivateRoute from "@kulturit/header-state/es/auths/PrivateRoute";
import {AuthsRoutes} from "@kulturit/header-state";


class AppRoutes extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path='/' allowAll={true} component={Home}/>
                <PrivateRoute exact path='/site' component={Site}/>
                <PrivateRoute exact path='/schools/municipality/:municipalityId' allowAll={true} component={Schools}/>
                <PrivateRoute exact path='/schools' allowAll={true} component={Schools}/>
                <PrivateRoute exact path='/schools/school/:schoolId' allowAll={true} component={School}/>
                <PrivateRoute exact path='/stories' component={Responses}/>
                <PrivateRoute exact path='/owners' component={Owners}/>
                <PrivateRoute exact path='/owners/:ownerId' component={EditOwner} />
                <PrivateRoute exact path='/owner/new' component={NewOwner} />
                <PrivateRoute exact path='/permissions' component={PermissionOwners} />
                <PrivateRoute exact path='/permissions/:ownerId' component={AssignPermissions}/>
                <AuthsRoutes/>
                <Route component={Page404}/>
            </Switch>
        )
    }
}

export default AppRoutes;

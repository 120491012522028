import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { Widget, WidgetHeader, WidgetBody, LayoutSection, LayoutFlex, Layout, LayoutField, ButtonPrimary, ButtonSecondary, ButtongroupInline, FieldInput } from "@frontend-components/admin";
import { extractSchools } from "../school/School.js";
import { kitFetchExternal } from "@kulturit/header-state";
import useStateWithCallback from 'use-state-with-callback';
import FieldLabel from "@frontend-components/admin/es/components/Field/FieldLabel";
import { nominalTypeHack } from "prop-types";

export const Municipality = ({ municipality }) => {

    const { t, i18n } = useTranslation();

    const history = useHistory();

    const [schools, setSchools] = useStateWithCallback(null, schools => {
        if (!!schools && !schools.done) {
            loadSchools();
        }
    });


    const [teacherPersonnummer, setTeacherPersonnummer] = useState("");

    const [validTeacherPersonnummer, setValidTeacherPersonnummer] = useState(false);
    const [teacherVerificationSchool, setTeacherVerificationSchool] = useState(null);
    const [teacherVerificationActive, setTeacherVerificationActive] = useState(false);

    function loadSchools(ac) {
        let municipalityUUID = municipality.key;
        let offset = 0;
        let done = false;
        if (!!schools) {
            offset = schools.nextOffset;
            done = schools.done;
        }
        if (!done) {
            kitFetchExternal(`/kulturnav-api/search/organization.placeReference.placeReference.place:${municipalityUUID},organization.type:dcfcd0a9-b835-41fb-8aa6-ed0062303e06/${offset}/20`, (ac) ? { signal: ac.signal } : {}).then(
                (json) => {
                    if (!Array.isArray(json) || !json.length) {
                        if (!!schools) {
                            setSchools({ ...schools, done: true });
                            console.log(`Loaded ${schools.loaded.length} schools for municipality ${municipality.name}, done`);
                        } else {
                            setSchools({ loaded: [], nextOffset: 0, done: false });
                            console.log(`Loaded 0 schools for municipality ${municipality.name}, done`);
                        }

                    } else {
                        let batchOfSchools = extractSchools(json, municipality);
                        let body = ({
                            "school_uuids": batchOfSchools.map(s => s.key)
                        });
                        kitFetchExternal('/minne-api/schools/actor_status', {
                            method: 'POST',
                            body: JSON.stringify(body)
                        }).then((json) => {
                            batchOfSchools.forEach(school => school["actorStatus"] = json.actorStatuses[school.key]);
                            if (!!schools) {
                                setSchools({ loaded: [...schools.loaded, ...batchOfSchools], nextOffset: offset + 20, done: false });
                            }
                            else {
                                setSchools({ loaded: batchOfSchools, nextOffset: offset + 20, done: false });
                            }
                        }).catch((err) => {
                            console.log(`Problem retriving actor stautuse for ${offset} batch of schools of municipality ${municipality.name}: ${err}`);
                            batchOfSchools.forEach(school => batchOfSchools["actorStatus"] = {});
                            if (!!schools) {
                                setSchools({ loaded: [...schools.loaded, ...batchOfSchools], nextOffset: offset + 20, done: false });
                            }
                            else {
                                setSchools({ loaded: batchOfSchools, nextOffset: offset + 20, done: false });
                            }
                        });
                    }

                }
            ).catch(err => {
                console.log(`Problem retriving schools for ${offset} batch of schools of municipality ${municipality.name}: ${err}`);
            });
        }
    }





    function makeActorTeacherAtSchool(school) {
        let body = { "personnummer": teacherPersonnummer }
        kitFetchExternal(`/minne-api/schools/school/${school.key}/teacher`, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then((json) => {
            history.push(`/schools/school/${school.key}`);
        });
    }

    function handleSchoolSelect(school) {
        if (school.actorStatus.isTeacherAtSchool) {
            let h = history;
            history.push(`/schools/municipality/${school.municipalityId}`);
            history.push(`/schools/school/${school.key}`);
        }
        else {
            setTeacherVerificationSchool(school);
            setTeacherVerificationActive(true);
        }
    }

    function handleTeacherVerification() {
        setTeacherVerificationActive(false);
        if (validTeacherPersonnummer) {
            makeActorTeacherAtSchool(teacherVerificationSchool);
        }
    }

    function abortTeacherVerification() {
        setTeacherVerificationActive(false);
    }

    function validatePNum(sPNum) {
        // Thanks to http://plea.se/me/validatePnum.html
        var numbers = sPNum.match(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/);
        var checkSum = 0;

        if (!isDate(sPNum.substring(0, 4), sPNum.substring(4, 6), sPNum.substring(6, 8))) {
            return false;
        }

        if (numbers == null) { return false; }

        var n;
        for (var i = 3; i <= 12; i++) {
            n = parseInt(numbers[i]);
            if (i % 2 === 0) {
                checkSum += n;
            } else {
                checkSum += (n * 2) % 9 + Math.floor(n / 9) * 9
            }
        }

        if (checkSum % 10 === 0) { return true; }
        return false;
    }

    function getYear(y) { return (y < 1000) ? y + 1900 : y; }

    function isDate(year, month, day) {
        month = month - 1; // 0-11 in JavaScript
        var tmpDate = new Date(year, month, day);
        if ((getYear(tmpDate.getYear()) == year) &&
            (month == tmpDate.getMonth()) &&
            (day == tmpDate.getDate()))
            return true;
        else
            return false;
    }

    function personnummerValidate(personnummer, event) {

        let valid = validatePNum(personnummer)
        setValidTeacherPersonnummer(valid);
        setTeacherPersonnummer(personnummer);

    }


    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1
        },
        content: {
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    useEffect(() => {
        const ac = new AbortController();
        setSchools({ loaded: [], nextOffset: 0, done: false });
        return () => ac.abort();
    }, [municipality]);


    return (
        <LayoutSection title={t('app.schools.texts.selectSchool')}>
            <LayoutFlex>
                <Modal
                    isOpen={teacherVerificationActive}
                    style={customStyles}
                    contentLabel="Verifiera att du är lärare">
                    <h1>{"Verifiera att du är lärare"}</h1>
                    <LayoutField>
                        <FieldLabel label={"Ange ditt personnummer:"}></FieldLabel>
                        <FieldInput title={"Personnummer"} placeholder="ååååmmddnnnn" value={teacherPersonnummer} onChange={personnummerValidate} required={true}></FieldInput>
                    </LayoutField>
                    <LayoutField>
                        <ButtongroupInline>
                            <ButtonPrimary disabled={!validTeacherPersonnummer} title="Bekräfta" icon="done" onClick={handleTeacherVerification}></ButtonPrimary>
                            <ButtonSecondary title="Avbryt" icon="close" onClick={abortTeacherVerification}></ButtonSecondary>
                        </ButtongroupInline>
                    </LayoutField>
                </Modal>
                {!!schools && !!schools.loaded && schools.loaded.map((school) => {
                    return <Layout key={school.key}>
                        <div title={school.actorStatus.isTeacherAtSchool ? "Klicka för att visa klasser på skolan" : "Klicka för att registrera dig som lärare på skolan"}>
                            <Widget 
                                onClick={e => handleSchoolSelect(school)} 
                                style={{ backgroundColor: school.actorStatus.isTeacherAtSchool ? "#008fad" : "#fff", color: school.actorStatus.isTeacherAtSchool ? "#fff" : "#000" }}
                            >
                                <WidgetHeader>
                                    <h1>{school.title}</h1>
                                </WidgetHeader>
                                <WidgetBody>
                                    <p>{school.address.street}<br />
                                        {school.address.postalCode} {school.address.postalPlace}</p>
                                </WidgetBody>
                            </Widget>
                        </div>
                    </Layout>;
                })}
            </LayoutFlex>
        </LayoutSection>
    );



}
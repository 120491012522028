import React, {useState} from 'react';
import {useHistory} from 'react-router-dom'
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {IconButton, Paper} from "@material-ui/core";
import {useResponses} from "./UseResponses";


export const ResponseList = () => {

    const history = useHistory();
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState("created");
    const [descending, setDescending] = useState(true);
    const [start, setStart] = useState(0);
    const [topic_id, setTopic_id] = useState("");
    const headersCells = ['id', 'title.sv_se', 'status', 'topic', 'author_alias', 'alias', 'anonymous', 'contributor_name', 'comments', 'created', 'reference_number'];

    const {responses} = useResponses({
        rows: rowsPerPage,
        sort: orderBy,
        start: start,
        topic_id: topic_id
    });

    const sortHandler = (headerCell) => {
        if(orderBy === headerCell) {
            setDescending(!descending);
        } else {
            setOrderBy(headerCell);
            setDescending(true);
        }
    };

    const rowsPerPageHandler = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const changePageHandler = newPage => {
        if (page < newPage) {
            setStart(start + rowsPerPage);
        } else {
            setStart(start - rowsPerPage);
        }
        setPage(newPage);
    };

    return (
        <Paper>
            <toolbar>
                <Tooltip title="Filter">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            </toolbar>
            <TableContainer>
                <Table aria-label="response table">
                    <TableHead>
                        <TableRow>
                            {headersCells.map(headerCell => (
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === headerCell}
                                        direction={descending ? "desc" : "asc"}
                                        onClick={() => sortHandler(headerCell)}
                                    >
                                        {headerCell}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {responses.map(response => (
                            <TableRow key={response.id}>
                                <TableCell>{response.id ? response.id : false}</TableCell>
                                <TableCell>{response.title.sv_se ? response.title.sv_se : false}</TableCell>
                                <TableCell>{response.status ? response.status : false}</TableCell>
                                <TableCell>{response.topic.string_id ? response.topic.string_id : false}</TableCell>
                                <TableCell>{response.username ? response.username : false}</TableCell>
                                <TableCell>{response.author_alias ? response.author_alias : false}</TableCell>
                                <TableCell>{response.author_anonymous ? response.author_anonymous : false}</TableCell>
                                <TableCell>{response.contributor_name ? response.contributor_name : false}</TableCell>
                                <TableCell>Comments</TableCell>
                                <TableCell>{response.created ? response.created : false}</TableCell>
                                <TableCell>{response.reference_number ? response.reference_number : false}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Rows per page"
                rowsPerPageOptions={[20, 30, 50, 100]}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={rowsPerPageHandler}
                component="div"
                // count={count}
                page={page}
                onChangePage={(event, newPage) => changePageHandler(newPage)}
            />
            {responses}
        </Paper>
    );
};
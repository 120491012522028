import React, { useEffect, useState } from "react";
import { Student } from "./Student.js"
import { kitFetchExternal } from "@kulturit/header-state";
import { Widget, WidgetHeader, WidgetBody, LayoutSection, LayoutFlex, Layout, Button, ButtonPrimary, ButtonSecondary, ButtongroupInline, LayoutField, FieldLabel, FieldInput, FieldSelect } from "@frontend-components/admin";
import "./School.scss";

export const SchoolClass = ({ schoolClass }) => {

    const [students, setStudents] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);

    const [newStudent, setNewStudent] = useState(null);

    const [teachers, setTeachers] = useState(null);

    const [newTeacher, setNewTeacher] = useState(null);

    const [possibleNewTeachers, setPossibleNewTeachers] = useState(null);


    function extractStudents(json) {
        let result = json.map(st => (
            {
                key: st.id,
                name: st.name,
                familyName: st.family_name,
                schoolClass: Object.assign({}, schoolClass),
                nbrOfMemories: st.nbrOfMemories,
                token: st.token,
                unselect: () => setSelectedStudent(null),
                remove: () => removeStudent(st.id)
            }
        ));
        return result;
    }

    function loadStudents(ac) {
        let schoolUUID = schoolClass.school.key;
        let schoolClassId = schoolClass.key
        kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/school_class/${schoolClass.key}/students`, (ac) ? { signal: ac.signal } : {})
            .then(json => {
                let students = extractStudents(json.students);
                setStudents(students);
                setSelectedStudent(null);
            })
            .catch(err => console.log(err));
    }

    function extractTeachers(json) {
        let result = json.map(t => (
            {
                key: t.id,
                name: t.name,
                email: t.email,
                personnummer: t.personnummer,
                schoolClasses: t.schoolClasses.map(c => c.id),
                isCurrentActor: t.isCurrentActor
                // unselect: () => setSelectedTeacher(null),
                // remove: () => removeTeacher(sc.id)
            }
        ));
        return result;
    }

    // function loadTeachers(ac) {
    //     kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/school_class/${schoolClass.key}/teachers`, (ac) ? { signal: ac.signal } : {})
    //         .then(json => {
    //             let teachers = extractTeachers(json.teachers);
    //             setTeachers(teachers);
    //         })
    //         .catch(err => console.log(err));
    // }

    function loadTeachers(ac) {
        kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/teachers`, (ac) ? { signal: ac.signal } : {})
            .then(json => {
                let teachers = extractTeachers(json.teachers);
                let teachersInThisClass = teachers.filter((t) => t.schoolClasses.includes(schoolClass.key))
                let teachersNotInThisClass = teachers.filter((t) => !t.schoolClasses.includes(schoolClass.key))
                setTeachers(teachersInThisClass);
                setPossibleNewTeachers(teachersNotInThisClass);
            })
            .catch(err => console.log(err));
    }

    function addTeacherToClass(teacher) {
        let rel = { school_class_id: schoolClass.key, teacher_id: teacher.key }
        kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/school_class_teacher`, {
            method: 'POST',
            body: JSON.stringify(rel)
        }).then(() => {
            loadTeachers();
        }
        ).catch(err => console.log(err));
    }

    function removeTeacherFromClass(teacher) {
        kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/school_class/${schoolClass.key}/teacher/${teacher.key}`, {
            method: 'DELETE'
        }).then(() => {
            loadTeachers();
        }
        ).catch(err => console.log(err));
    }

    function addStudent(student) {
        kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/school_class/${schoolClass.key}/student`, {
            method: 'POST',
            body: JSON.stringify(student)
        }).then(() => {
            loadStudents();
        }
        ).catch(err => console.log(err));
    }

    function removeStudent(student) {
        kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/school_class/${schoolClass.key}/student/${student.key}`, {
            method: 'DELETE'
        }).then(() => {
            loadStudents();
        }
        ).catch(err => console.log(err));
    }


    function initializeNewStudent() {
        setNewStudent({ name: "", valid: false });
    }

    function updateNewStudentName(newName) {
        let updatedNewStudent = { ...newStudent, name: newName };
        updatedNewStudent = { ...updatedNewStudent, valid: isValidNewStudent(updatedNewStudent) }
        setNewStudent(updatedNewStudent);

    }

    function updateNewStudentFamilyName(newFamilyName) {
        let updatedNewStudent = { ...newStudent, familyName: newFamilyName };
        updatedNewStudent = { ...updatedNewStudent, valid: isValidNewStudent(updatedNewStudent) }
        setNewStudent(updatedNewStudent);

    }

    function isValidNewStudent(student) {
        let valid = student.name !== null && student.name !== "";
        valid = valid && student.familyName !== null && student.familyName !== "";
        return valid
    }

    function addNewStudent() {
        if (newStudent.valid) {
            addStudent(newStudent);
            setNewStudent(null);
        }
    }

    function clearNewStudent() {
        setNewStudent(null);
    }

    function initializeNewTeacher() {
        setNewTeacher(possibleNewTeachers[0]);
    }

    function updateNewTeacher(key) {
        let n = possibleNewTeachers.find((t) => t.key === key);
        setNewTeacher(n);
    }

    function addNewTeacher() {
        addTeacherToClass(newTeacher);
        setNewTeacher(null);
    }

    function clearNewTeacher() {
        setNewTeacher(null);
    }

    function handleSelectStudent(e, student) {
        if(!e.target.classList.contains("admin-button__icon")) {
            setSelectedStudent(student);
        }
    }

    function handleRemoveStudent(e, student) {
        e.preventDefault();
        removeStudent(student);
    }

    useEffect(() => {
        const ac = new AbortController();
        loadStudents(ac);
        loadTeachers(ac);
        return () => ac.abort();
    }, [schoolClass]);

    if (!!selectedStudent) {
        return (<Student student={selectedStudent}></Student>);
    } else {
        return (
            <div>
                <div className="header">
                    <Button
                        title={"Visa alla klasser på " + schoolClass.school.title}
                        onClick={e => schoolClass.unselect()}
                        icon="chevron_left" >
                    </Button>
                </div>
                <LayoutSection title={"Elever i klass " + schoolClass.title + ' på ' + schoolClass.school.title }>
                    <LayoutFlex>
                        {!!students && students.map((student) =>
                            <Layout key={student.key}>
                                <Widget onClick={e => handleSelectStudent(e,student)}>
                                    <WidgetHeader style={{ height: "30px" }}>
                                        <h1><span>{student.name}</span> <span>{student.familyName}</span>
                                            {true &&
                                                <Button 
                                                    style={{ float: "right" }}
                                                    onClick={e => handleRemoveStudent(e, student)}
                                                    icon="delete" >
                                                </Button>
                                            }
                                        </h1>
                                    </WidgetHeader>
                                    <WidgetBody>
                                        <p>Lösenord: {student.token}</p>
                                        <p>Minnen: {student.nbrOfMemories}</p>
                                    </WidgetBody>
                                </Widget>
                            </Layout>
                        )}
                        <Widget>
                            {!newStudent &&
                                <ButtonPrimary title="Lägg till elev" icon="add" onClick={initializeNewStudent}></ButtonPrimary>
                            }
                            {!!newStudent &&
                                <LayoutField>
                                    <FieldLabel label="Ny elev - Förnamn"></FieldLabel>
                                    <FieldInput
                                        title={"Namn"}
                                        value={newStudent.name}
                                        onChange={updateNewStudentName}
                                        required={true}></FieldInput>
                                    <FieldLabel label="Efternamn (visas aldrig öppet)"></FieldLabel>
                                    <FieldInput
                                        title={"Efternamn"}
                                        value={newStudent.familyName}
                                        onChange={updateNewStudentFamilyName}
                                        required={true}></FieldInput>                                        
                                    <ButtongroupInline>
                                        <ButtonPrimary disabled={!newStudent.valid} title="Spara" icon="done" onClick={addNewStudent}></ButtonPrimary>
                                        <ButtonSecondary title="Avbryt" icon="close" onClick={clearNewStudent}></ButtonSecondary>
                                    </ButtongroupInline>
                                </LayoutField>
                            }
                        </Widget>
                    </LayoutFlex>

                </LayoutSection>
                <LayoutSection title={"Lärare i klass " + schoolClass.title}>
                    <LayoutFlex>
                        {!!teachers && teachers.map((teacher) =>
                            <Layout key={teacher.key}>
                                <Widget style={{ backgroundColor: teacher.isCurrentActor ? "white" : "lightgrey" }}>
                                    <WidgetHeader style={{ height: "30px" }}>
                                        <h1><span>{teacher.name}</span>
                                        {!teacher.isCurrentActor &&
                                            <Button
                                                style={{ float: "right" }}
                                                onClick={e => removeTeacherFromClass(teacher)}
                                                icon="delete" >
                                            </Button>
                                        }
                                        </h1>
                                    </WidgetHeader>
                                    <WidgetBody>
                                        <p>{teacher.email}</p>
                                    </WidgetBody>
                                </Widget>
                            </Layout>
                        )}

                        {!newTeacher && possibleNewTeachers && possibleNewTeachers.length > 0 &&
                            <Widget>
                                <ButtonPrimary title="Lägg till lärare" icon="add" onClick={initializeNewTeacher}></ButtonPrimary>
                            </Widget>
                        }
                        {!!newTeacher &&
                            <Widget>
                                <LayoutField>
                                    <FieldLabel label="Lärare"></FieldLabel>
                                    <FieldSelect
                                        title={"Årskurs"}
                                        options={possibleNewTeachers.map((t) => ({ value: t.key, label: t.name }))}
                                        value={newTeacher.key}
                                        onChange={updateNewTeacher}
                                        required={true}></FieldSelect>
                                    <ButtongroupInline>
                                        <ButtonPrimary disabled={!newTeacher} title="Spara" icon="done" onClick={addNewTeacher}></ButtonPrimary>
                                        <ButtonSecondary title="Avbryt" icon="close" onClick={clearNewTeacher}></ButtonSecondary>
                                    </ButtongroupInline>
                                </LayoutField>
                            </Widget>
                        }
                    </LayoutFlex>
                </LayoutSection>
            <div className="footer">
                <Button
                    title={"Visa alla klasser på " + schoolClass.school.title}
                    onClick={e => schoolClass.unselect()}
                    icon="chevron_left" >
                </Button>
            </div>
            </div >
        );
    }
}
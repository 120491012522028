import {LayoutFieldset} from "@frontend-components/admin";
import {TextInput} from "../../input/TextInput";
import React from "react";

export const renderSection = (globalValues, setGlobalValues, currentSection, sections) => {
    return <LayoutFieldset title={'Edit web page'} >
        {Object.keys(sections[currentSection]).map(option => {
            return (
                <TextInput
                    title={option}
                    value={sections[currentSection][option]['sv_se']}
                    onChange={(value) => setGlobalValues({
                        ...globalValues,
                        [option]: {
                            ...globalValues[currentSection],
                            ['sv_se']: value
                        }
                    })}
                />
            )
        })}
    </LayoutFieldset>;
};
import React, {useState} from 'react';
import {LayoutEditor} from "@frontend-components/admin";
import {NewOwnerData} from "./NewOwnerData"
import {useHistory} from 'react-router-dom'
import {kitFetchExternal} from "@kulturit/header-state";

export const NewOwner = () => {
    const [owner, setOwner] = useState({});
    const history = useHistory();
    return (
        <LayoutEditor
            button={{
                title: 'Save',
                onClick: () => {
                    const payload = {
                        identifier: owner.identifier,
                        name: owner.name,
                        logo: owner.logo,
                        owner_type_id: owner.owner_type_id,
                        options: owner.options,
                        web: owner.web,
                        email: owner.email,
                        feedback_email: owner.feedback_email,
                        description: owner.description
                    };
                    kitFetchExternal('/minne-api/owners/put_owner', {
                        method: 'POST',
                        body: JSON.stringify(payload)
                    }).then(() => history.push('/owners'));
                }

            }}
            parents={[
                {
                    title: "Home",
                    url: "/"
                },
                {
                    title: "Owners",
                    url: "/owners"
                },
                {
                    title: "New owner",
                    url: "/owner/new"
                }
            ]}>
            <NewOwnerData
                owner={owner}
                setOwner={setOwner}
            />
        </LayoutEditor>
    );
};
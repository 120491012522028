import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {useFinderMode} from "../layout/hooks/useFinderMode";
import {kitFetchExternal} from "@kulturit/header-state";

export const UseOwnerTypes = () => {
    const dispatch = useDispatch();
    const [ownerTypes, setOwnerTypes] = useState(null);
    useFinderMode();

    useEffect(() => {
        kitFetchExternal('/minne-api/owners/owner_types')
            .then(json => {setOwnerTypes(json.owner_types)})
            .catch(err => console.log("Owners fetch error: " + err));
    },  [dispatch, setOwnerTypes]);
    return ownerTypes;
};
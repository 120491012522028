import {FieldEditor, LayoutField} from "@frontend-components/admin";
import React from "react";

export const Richtext = ({title, help, errors, value, onChange}) => {

    return (
        <LayoutField title={title} help={help} errors={errors} >
            <FieldEditor
                value={value}
                required={true}
                onChange={onChange}
            />
        </LayoutField>
    )
};
import { getSchool as getSchoolAction } from "../state/school/school-action";

export function extractSchools(json) {
    let result = json.map(entityJson => extractSchool(entityJson));
    return result;
}

export function extractSchool(json) {
    return {
             key: json.uuid,
             title: json.properties["entity.name"][0].value.sv,
             address: {
                 street: json.properties["organization.address"][0].value.properties["address.line1"][0].value,
                 postalCode: json.properties["organization.address"][0].value.properties["address.postalCode"][0].value,
                 postalPlace: json.properties["organization.address"][0].value.properties["address.postalPlace"][0].value,
             },
             municipalityId: json.properties["organization.placeReference"][0].value.properties["placeReference.place"][0].value
         };
 }

export function getSchool(schoolId, dispatch, cache = null) {
    let school = null;
    let schoolFuture;
    if (cache && cache[schoolId]) {
        school = cache[schoolId];
    } else {
        let futureSchool, noFutureSchool;
        schoolFuture =
            new Promise(function (resolve, reject) {
                futureSchool = resolve;
                noFutureSchool = reject;
            });
        dispatch(getSchoolAction(schoolId, futureSchool, noFutureSchool));
    }

    return new Promise((resolve, reject) => {
        if (school) {
            resolve(school);
        } else {
            schoolFuture
                .then(school => {
                    resolve(school);
                }).catch(err => {
                    reject(err);
                });
        }
    });
}
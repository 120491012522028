import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {kitFetchExternal} from "@kulturit/header-state";

export const useOwners = ({query="", start=0, rowsPerPage=5, orderBy='id', descending=true}) => {
    const dispatch = useDispatch();
    const [ownersResponse, setOwnersResponse] = useState({owners: [], query: "", count: 0, rows: rowsPerPage, start: start});

    useEffect(() => {
        kitFetchExternal(`/minne-api/owners/search?query=${query}&rows=${rowsPerPage}&start=${start}&sort=${orderBy}`)
            .then(json => setOwnersResponse(json))
            .catch(err => console.log("Owners fetch error: " + err));
    },  [dispatch, setOwnersResponse, query, rowsPerPage, start, orderBy]);

    return ownersResponse;
};
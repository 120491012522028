import {SCHOOL_RECEIVED} from "./school-action";

const initialState = {
    schools: []
};

const schoolReducer = (state = initialState, action) => {
    switch (action.type) {   
        case SCHOOL_RECEIVED: {
                
                return {
                    ...state,
                    schools: {
                        ...state.schools,
                        [action.schoolId]: action.school
                    }
                }
            }
        default:
            return {
                ...state
            }
    }
};

export default schoolReducer

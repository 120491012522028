import { kitFetchExternal } from "@kulturit/header-state";
import { extractSchool } from "../../school/School";

export const SCHOOL_FETCHED = 'school/schoolFetched';

export const SCHOOL_RECEIVED = 'school/schoolReceived';

export const getSchool = (schoolId, callback=null, onErrorCallback=null) => dispatch => {
    dispatch({
        type: SCHOOL_FETCHED, schoolId: schoolId
    })
    kitFetchExternal(`/kulturnav-api/${schoolId}`)
        .then(json => {
            let school = extractSchool(json);
            if(callback) {
                callback(school);
            }
            dispatch({
                type: SCHOOL_RECEIVED,
                topicId: schoolId,
                topic: school
            })
        })
        .catch(err => {
            onErrorCallback && onErrorCallback(err)
        })
}
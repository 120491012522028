import React from "react";
import {useFinderMode} from "../layout/hooks/useFinderMode";
import {useOwners} from "../owners/useOwners";
import {FinderModule, FinderSection} from "@frontend-components/admin";
import LayoutFinder from "@frontend-components/admin/es/components/Finder/LayoutFinder";

export const PermissionOwners = () => {
    useFinderMode();

    const {owners} = useOwners({});

    if(!owners){
        return null
    }

    return (
        <LayoutFinder
            parents={[
                {
                    title: "Home",
                    url: "/"
                },
                {
                    title: "Permission owners",
                    url: "/permissions"
                }
            ]}>
            <FinderSection>
                {owners.map(owner => (
                    <FinderModule
                        key={owner.id}
                        title={owner.name}
                        label={owner.identifier}
                        status='Status'
                        description='Description'
                        moduleLayout='list'
                        moduleSize='Auto'
                        moduleAction='link'
                        documentType='permissions'
                        id={owner.id}
                        uniqueId={owner.id}
                    />
                ))}
            </FinderSection>
        </LayoutFinder>
    );
};

import {TextInput} from "../input/TextInput";
import {Richtext} from "../input/Richtext";
import {OwnerTypeSelect} from "../component/ownerTypeSelect";
import React from "react";

export const EditOwnerData = ({owner, setOwner}) => {

    function ownerTypeChangeHandler(value) {
        setOwner({
            ...owner,
            owner_type_id: value
        })
    }

    return <>
        <TextInput
            title='Identifier'
            value={owner.identifier}
            onChange={(value) => {
                setOwner({
                    ...owner,
                    identifier: value
                })
            }}
        />
        <TextInput
            title='Name'
            value={owner.name}
            onChange={(value) => {
                setOwner({
                    ...owner,
                    name: value
                })
            }}
        />
        <TextInput
            title={"Logo"}
            value={owner.logo}
            onChange={(value) => {
                setOwner({
                    ...owner,
                    logo: value
                })
            }}
        />

        <OwnerTypeSelect onChange={ownerTypeChangeHandler} owner_type_id={owner.owner_type_id}/>

        <TextInput
            title={"Owner information"}
            value={owner.options}
            onChange={(value) => {
                setOwner({
                    ...owner,
                    options: value
                })
            }}
        />
        <TextInput
            title={"Home page"}
            value={owner.web}
            onChange={(value) => {
                setOwner({
                    ...owner,
                    web: value
                })
            }}
        />
        <TextInput
            title={"Email"}
            value={owner.email}
            onChange={(value) => {
                setOwner({
                    ...owner,
                    email: value
                })
            }}
        />
        <TextInput
            title={"Feedback email"}
            value={owner.feedback_email}
            onChange={(value) => {
                setOwner({
                    ...owner,
                    feedback_email: value
                })
            }}
        />
        <Richtext
            title={"Description - Need rich text editor here"}
            value={owner.description}
            onChange={(value) => {
                setOwner({
                    ...owner,
                    description: value
                })
            }}
        />
    </>;
};